import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// Material-UI
import { Grid, Divider, Typography, Paper, Button } from "@mui/material";
//Queries
import { useQuery, useMutation } from "@apollo/client";
// Components
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { ProductElement } from "../../../Components/Product";
import { CompanyInfo } from "../components/CompanyInfo";
import { OrderSummary } from "../../History/components/OrderSummary";
import { OrderInfo } from "../components/OrderInfo";
import { ServerError } from "../../../Components/ServerError";
import { OrderApproved } from "../components/OrderApproved";
import { OrderRejected } from "../components/OrderRejected";
import { OrderCancelled } from "../components/OrderCancelled";
// Utils
import { formatValue } from "../../../Utils/markingProduct";
import { getFragmentData, graphql } from "../../../gql";
import {
  GetOrderQuery,
  orderFragment,
  orderPositionFragment,
} from "../../History/pages/OrderDetail";

export const approveOrderMutation = graphql(/* GraphQL */ `
  mutation approveOrder($input: OrderApprovalMutationInput!) {
    approveOrRejectOrder(input: $input) {
      success
      errors
    }
  }
`);

interface Props {
  match: {
    params: {
      orderId: string;
    };
  };
}

export const ApprovalPage = (props: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const orderIdWithToken = props.match.params.orderId;
  const orderId = props.match.params.orderId.split("$")[0];
  const [isApproved, setApproved] = React.useState(false);
  const [isRejected, setRejected] = React.useState(false);
  const [isServerError] = React.useState(false);

  const { data, loading, error } = useQuery(GetOrderQuery, {
    variables: { uuid: orderId },
  });

  const [approveOrReject] = useMutation(approveOrderMutation);

  if (error) {
    throw error;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!data?.order) return <Typography>{t("No orders")}</Typography>;
  // Reading fragment data
  const order = getFragmentData(orderFragment, data?.order);

  const approveOrder = async () => {
    try {
      const { data } = await approveOrReject({
        variables: {
          input: {
            securedUuid: orderIdWithToken,
            approve: true,
          },
        },
        refetchQueries: () => [
          { query: GetOrderQuery, variables: { uuid: orderId } },
        ],
      });

      if (
        data &&
        data.approveOrRejectOrder &&
        data.approveOrRejectOrder.success
      ) {
        setApproved(true);
      } else if (
        data &&
        data.approveOrRejectOrder &&
        data.approveOrRejectOrder.errors.message === "Permission denied."
      ) {
        enqueueSnackbar(
          t("You do not have permissions to approve/reject an order."),
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(t("You've already approved/rejected this order."), {
          variant: "error",
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const rejectOrder = async () => {
    try {
      const { data } = await approveOrReject({
        variables: {
          input: {
            securedUuid: orderIdWithToken,
            approve: false,
          },
        },
      });
      if (
        data &&
        data.approveOrRejectOrder &&
        data.approveOrRejectOrder.success
      ) {
        setRejected(true);
      } else if (
        data &&
        data.approveOrRejectOrder &&
        data.approveOrRejectOrder.errors.message === "Permission denied."
      ) {
        enqueueSnackbar(
          t("You do not have permissions to approve/reject an order."),
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(t("You've already approved/rejected this order."), {
          variant: "error",
        });
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      {isServerError ? (
        <ServerError />
      ) : isApproved ? (
        <OrderApproved />
      ) : isRejected ? (
        <OrderRejected />
      ) : order!.state === "ORDER_CANCELLED" ? (
        <OrderCancelled orderId={order.orderId} />
      ) : (
        <>
          <Paper>
            <Grid
              container
              alignContent="center"
              justifyContent="space-between"
            >
              <Grid item xs={3} style={{ padding: "20px" }}>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={rejectOrder}
                >
                  {t("Reject")}
                </Button>
              </Grid>
              <Grid item style={{ padding: "20px" }}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={approveOrder}
                >
                  {t("Approve")}
                </Button>
              </Grid>
            </Grid>
            <Divider />

            <CompanyInfo companyId={order!.company.uuid} />
            <OrderInfo order={data.order} />

            <Grid container alignContent="center">
              <Grid item xs={12} md={8}>
                <Typography variant="h6" style={{ padding: "20px" }}>
                  {order.orderPositions.edges.length <= 1
                    ? t("PRODUCT")
                    : t("PRODUCTS")}
                </Typography>
                <Divider />
                {order.orderPositions.edges.map(
                  (orderPosition, index: number) => {
                    const orderNode = getFragmentData(
                      orderPositionFragment,
                      orderPosition?.node
                    );

                    if (!orderNode) throw error;

                    const {
                      markingProduct,
                      quantity,
                      total,
                      price,
                      additionalInfo,
                      markable,
                    } = orderNode;
                    return (
                      <Grid
                        container
                        style={{ padding: "16px" }}
                        key={index}
                        alignItems="flex-start"
                      >
                        <ProductElement
                          price={price}
                          product={markingProduct}
                          quantity={quantity}
                          primary={{
                            [`${t("Subtotal")}`]: formatValue(
                              "currency",
                              total
                            ),
                          }}
                          cardSize={200}
                          additionalInfo={additionalInfo}
                          markableId={markable.uuid}
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="h6" style={{ padding: "20px" }}>
                  {t("ORDER SUMMARY")}
                </Typography>
                <Divider />
                <OrderSummary order={data.order} />
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
};
